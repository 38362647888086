import './App.css';
import './load.css';
import './musicbar.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Tracks from './pages/Tracks'
import Artists from './pages/Artists'
import Recent from './pages/Recent'
import Playlist from './pages/Playlist'

const redirect_uri = "https://www.winniesspotify.com/"; // Your redirect URI
const client_id = '3629edd575284d0aa6671c69ae8fb3d3';
const client_secret = 'c086865ccb2b49718e078e6dab0c6a0b';
var access_token = null;
var refresh_token = null;
var selectedArtists = []; // Array to store selected artist names

const AUTHORIZE = "https://accounts.spotify.com/authorize";
const TOKEN = "https://accounts.spotify.com/api/token";

export async function fetchWebApi(endpoint, method, body) {
  let accessToken = sessionStorage.getItem("access_token");

  if (!accessToken) {
    console.error("Access token not found");
    return null;
  }

  // Check if token is expired
  const expiration = parseInt(sessionStorage.getItem("token_expires_at"), 10);
  if (Date.now() >= expiration * 1000) {
    console.log("Token expired. Refreshing...");
    await refreshAccessToken();
    accessToken = sessionStorage.getItem("access_token"); // Get the updated access token
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const res = await fetch(`https://api.spotify.com/${endpoint}`, options);

  if (res.status === 401) {
    console.log("Token expired or invalid, refreshing...");
    await refreshAccessToken();
    accessToken = sessionStorage.getItem("access_token"); // Get the updated access token
    headers.Authorization = `Bearer ${accessToken}`;
    options.headers = headers;
    return fetch(`https://api.spotify.com/${endpoint}`, options);
  }

  return await res.json();
}

export async function refreshAccessToken() {
  console.log("Refreshing access token...");
  const refreshToken = sessionStorage.getItem("refresh_token");
  const client_id = sessionStorage.getItem("client_id");
  const client_secret = sessionStorage.getItem("client_secret");

  if (!refreshToken || !client_id || !client_secret) {
    console.error("Refresh token or client credentials not found");
    return null;
  }

  const body = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${client_id}&client_secret=${client_secret}`;

  try {
    const response = await fetch(TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    });

    if (response.ok) {
      const data = await response.json();
      const newAccessToken = data.access_token;
      const expiresIn = data.expires_in;

      sessionStorage.setItem("access_token", newAccessToken);
      // Calculate the new expiration time
      const now = new Date();
      const expiresAt = now.getTime() + expiresIn * 1000;
      sessionStorage.setItem("token_expires_at", expiresAt);

      console.log("Access token refreshed successfully");
      return newAccessToken;
    } else {
      console.error("Failed to refresh access token");
      return null;
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
    return null;
  }
}

export async function displayTopTracks(timeRange) {
  const topTracks = await fetchWebApi(`v1/me/top/tracks?limit=50&time_range=${timeRange}`, 'GET');
  return topTracks.items;
}

export async function displayUserProfile() {
  const profile = await fetchWebApi(`v1/me`, `GET`);
  return profile;
}

export async function displayTopArtists(timeRange) {
  var start = document.getElementById("start");
  var getStarted = document.getElementById("getStartedButton");

  // Check if elements exist before trying to access their style
  if (start) {
    start.style.display = "none";
  }
  if (getStarted) {
    getStarted.style.display = "none";
  }

  const topArtists = await fetchWebApi(`v1/me/top/artists?limit=50&time_range=${timeRange}`);
  return topArtists.items;
}

export async function displayRecentTracks() {
  await displayCurrentlyPlayingTrack();
  const recentTracks = await fetchWebApi('v1/me/player/recently-played?limit=50', 'GET');
  return recentTracks && recentTracks.items ? recentTracks.items : [];
}

export async function displayCurrentlyPlayingTrack() {
  try {
    const currentlyPlayingTrack = await fetchWebApi('v1/me/player/currently-playing', 'GET');

    if (!currentlyPlayingTrack || !currentlyPlayingTrack.item) {
      throw new Error("No track is currently playing.");
    }

    return currentlyPlayingTrack.item;
  } catch (error) {
    return;
  }
}

export function requestAuthorization() {
  const code = getCode();

  if (code) {
    // Authorization code is available, fetch the access token
    fetchAccessToken(code);
  } else {
    // Authorization code is not available, initiate the authorization flow
    sessionStorage.setItem("client_id", client_id);
    sessionStorage.setItem("client_secret", client_secret);

    let url = AUTHORIZE;
    url += "?client_id=" + client_id;
    url += "&response_type=code";
    url += "&redirect_uri=" + encodeURI(redirect_uri);
    url += "&show_dialog=true";
    url += "&scope=playlist-modify-public playlist-modify-private user-read-private user-read-email user-library-read user-top-read user-read-recently-played user-read-currently-playing";

    window.location.href = url;
  }
}

export function getCode() {
  let code = null;
  const queryString = window.location.search;
  if (queryString.length > 0) {
    const urlParams = new URLSearchParams(queryString);
    code = urlParams.get('code')
  }
  console.log("Authorization code:", code);
  return code;
}

export async function fetchAccessToken(code) {
  let body = "grant_type=authorization_code";
  body += "&code=" + code;
  body += "&redirect_uri=" + encodeURI(redirect_uri);
  body += "&client_id=" + client_id;
  body += "&client_secret=" + client_secret;
  await callAuthorizationApi(body);
}

export function callAuthorizationApi(body) {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", TOKEN, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xhr.setRequestHeader('Authorization', 'Basic ' + btoa(client_id + ":" + client_secret));
  xhr.send(body);
  xhr.onload = handleAuthorizationResponse;
}

function handleAuthorizationResponse() {
  if (this.status === 200) {
    var data = JSON.parse(this.responseText);
    console.log("Authorization response data:", data);
    if (data.access_token !== undefined) {
      access_token = data.access_token;
      sessionStorage.setItem("access_token", data.access_token);
      console.log("Access token saved to session storage");
    }
    if (data.refresh_token !== undefined) {
      refresh_token = data.refresh_token;
      sessionStorage.setItem("refresh_token", data.refresh_token);
      console.log("Refresh token saved to session storage");
    }
    if (data.code !== undefined) {
      sessionStorage.setItem("code", data.code);
      console.log("Authorization code saved to session storage");
    }
  } else {
    console.log("Authorization request failed with status:", this.status);
  }
}

export async function getSavedTracks(offset) {
  try {
    const url = `v1/me/tracks?limit=50&offset=${offset}`;
    const savedTracks = await fetchWebApi(url, 'GET');
    return savedTracks.items;
  } catch (error) {
    throw new Error(`Error fetching saved tracks: ${error.message}`);
  }
}

export async function getRecommendations(trackId) {
  // Fetching recommendations based on a track
  const recommendations = await fetchWebApi(
    `v1/recommendations?limit=1&seed_tracks=${trackId}`, 'GET'
  );
  return recommendations.tracks;
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export async function createPlaylist(tracksUri) {
  try {
    const { id: user_id } = await fetchWebApi('v1/me', 'GET');
    shuffleArray(tracksUri);

    const playlist = await fetchWebApi(
      `v1/users/${user_id}/playlists`, 'POST', {
      "name": "For you",
      "description": generatePlaylistDescription(selectedArtists),
      "public": false
    },
    );

    if (!playlist || !playlist.id) {
      throw new Error("Playlist ID not found");
    }

    // Convert tracks URIs to objects with URIs
    const tracks = tracksUri.map(uri => ({ "uri": uri }));

    // Add tracks one by one to ensure they are added sequentially
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      await fetchWebApi(
        `v1/playlists/${playlist.id}/tracks`,
        'POST',
        { "uris": [track.uri] }, // Each track should be added as an array
        sessionStorage.getItem("access_token")
      );
    }

    return playlist;
  } catch (error) {
    console.error("Error creating playlist:", error);
    throw error;
  }
}

function generatePlaylistDescription(artists) {
  if (artists.length === 1) {
    return `A personalized playlist featuring ${artists[0]} and more`;
  } else if (artists.length === 2) {
    return `A personalized playlist featuring ${artists[0]}, ${artists[1]}, and more`;
  } else {
    return `A personalized playlist featuring ${artists[0]}, ${artists[1]}, ${artists[2]} and more`;

  }
}

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/tracks" element={<Tracks />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/recent" element={<Recent />} />
          <Route path="/playlist" element={<Playlist />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
