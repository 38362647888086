import { Link } from 'react-router-dom';
import logo from '../img/logo.png'

export default function Header() {

    return (
        <>
            <Link to="/">
                <img src={logo} alt="Logo" className="logo" />
            </Link>

            <div className="header">
                <div className="header-row">
                    <Link to="/tracks">Top Tracks</Link>
                    <Link to="/artists">Top Artists</Link>
                </div>
                <div className="header-row">
                    <Link to="/recent">Recently Played</Link>
                    <Link to="/playlist">Playlist Generator</Link>
                </div>
            </div>

        </>
    );
}