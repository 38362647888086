import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import spotifyIcon from '../img/icon.svg';
import { requestAuthorization, displayUserProfile, getCode, fetchAccessToken } from '../App';

export default function Home() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [headerVisible, setHeaderVisible] = useState(false); // State to control header visibility

    useEffect(() => {
        const getProfile = async () => {
            try {
                const userProfile = await displayUserProfile();
                setProfile(userProfile);
                setLoading(false);
                if (userProfile && userProfile.display_name) {
                    setHeaderVisible(true); // Show header when profile is available with display name
                } else {
                    setHeaderVisible(false); // Hide header if no display name
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
                setLoading(false);
            }
        };

        // Call getProfile after 3 seconds
        const timer = setTimeout(() => {
            getProfile();
        }, 300);

        return () => clearTimeout(timer); // Cleanup timer

    }, []);

    useEffect(() => {
        const handleTokens = async () => {
            const accessToken = sessionStorage.getItem('access_token');
            const refreshToken = sessionStorage.getItem('refresh_token');
            if (accessToken && refreshToken) {
                // Tokens are available, you can use them for further operations
                // Fetch the updated profile when access token changes
                const userProfile = await displayUserProfile();
                setProfile(userProfile);
                if (userProfile && userProfile.display_name) {
                    setHeaderVisible(true); // Show header when profile is available with display name
                } else {
                    setHeaderVisible(false); // Hide header if no display name
                }
            }
        };

        handleTokens(); // Check tokens on initial render

        // Listen for changes in tokens
        window.addEventListener('storage', handleTokens);

        return () => {
            // Clean up event listener
            window.removeEventListener('storage', handleTokens);
        };
    }, []);

    useEffect(() => {
        // Check if there's an authorization code in sessionStorage
        const code = getCode();
        if (code) {
            // Authorization code is available, fetch the access token
            fetchAccessToken(code);
        }
    }, []);

    return (
        <>
            <Header style={{ visibility: headerVisible ? 'visible' : 'hidden' }} /> {/* Render Header with visibility control */}
            <div id="landing-page-content">
                {loading ? (
                    <p>Loading...</p>
                ) : profile ? (
                    <>
                        <h1>Hey, {profile.display_name || 'User'}!</h1>
                        {profile.images && profile.images.length > 0 && (
                            <img src={profile.images[0].url} alt="Profile" className="profile-image" />
                        )}
                        <p>Discover new music with personalized playlists, and view your top tracks songs, genres, and listening history.</p>
                        {/* Logout Button */}
                        <button onClick={requestAuthorization} id="changeButton">
                            Change profile
                        </button>
                    </>
                ) : (
                    <>
                        <h1>Welcome to Winnie's Spotify App!</h1>
                        <p>Discover new music with personalized playlists, and view your top tracks songs, genres, and listening history.</p>
                        <p id="loginp">Log in with your Spotify account to get started:</p>
                        <button id="loginButton" onClick={requestAuthorization}>
                            <img src={spotifyIcon} alt="spotify icon" className="spotifyIcon" /> Log in to Spotify
                        </button>
                    </>
                )}
            </div>
        </>
    );
}