import { useEffect, useState } from 'react';
import Header from '../components/Header';
import { displayRecentTracks, displayCurrentlyPlayingTrack } from '../App';
import '../musicbar.css'; // Adjust the path as needed

export default function Recent() {
    const [tracks, setTracks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTrack, setCurrentTrack] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
        const fetchedTracks = await displayRecentTracks();
        setTracks(fetchedTracks);

        // Check if there is a currently playing track
        const playingTrack = await displayCurrentlyPlayingTrack();
        if (playingTrack === 'NO_TRACK_PLAYING') { // Replace with the specific value/object returned by displayCurrentlyPlayingTrack
            setCurrentTrack(null); // No track playing, set currentTrack to null
        } else {
            setCurrentTrack(playingTrack);
        }
    } catch (error) {
        
        setError('An error occurred while fetching data.');
    }
    setIsLoading(false);
};

        fetchData();
    }, []);

    // Updated formatPlayedDate function to display time in 12-hour format
    function formatPlayedDate(playedAt) {
        const playedDate = new Date(playedAt);
        const hours = playedDate.getHours();
        const minutes = playedDate.getMinutes().toString().padStart(2, '0');
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        return `${formattedHours}:${minutes} ${amOrPm}`;
    }

    return (
        <>
            <Header />
            <h1 id='titleHeader'>Recently Played</h1>
            <div id="tracksContainer">
                {error && <p className="error-message">{error}</p>}
                {!isLoading && (
                    <>
                        {currentTrack && (
                            <div className="current-track-container">
                                {/* Music Bar Animation */}
                                <div className="now playing">
                                    <div className="now-playing" id="music">
                                        <span className="bar n1"></span>
                                        <span className="bar n2"></span>
                                        <span className="bar n3"></span>
                                        <span className="bar n4"></span>
                                        <span className="bar n5"></span>
                                    </div>
                                </div>
                                {/* End Music Bar Animation */}

                                <a href={currentTrack.external_urls.spotify} target="_blank" rel="noopener noreferrer" className="track-link">
                                    <div className="track">
                                        <div className="artwork-info">
                                            <div className="album-div">
                                                <img src={currentTrack.album.images[0].url} alt="Album Artwork" className="album-image" />
                                            </div>
                                            <div className="track-details">
                                                <div className="track-name">{currentTrack.name}</div>
                                                <div className="track-artists">{currentTrack.artists.map(artist => artist.name).join(', ')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )}

                        <ul id="recentTracksList" className="recent-tracks-list">
                            {tracks.map((track, index) => (
                                <li key={index} className="track-item">
                                    <a href={track.track.external_urls.spotify} target="_blank" rel="noopener noreferrer" className="track-link">
                                        <div className="track">
                                            <div className="track-number">
                                                <span>{index + 1}</span>
                                            </div>
                                            <img src={track.track.album.images[0].url} alt="Album Artwork" className="album-image" />
                                            <div className="track-details">
                                                <div className="track-name">{track.track.name}</div>
                                                <div className="track-artists">{track.track.artists.map(artist => artist.name).join(', ')}</div>
                                                <div className="played-at">
                                                    Played: {formatPlayedDate(track.played_at)}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {isLoading && <p>Loading...</p>}
            </div>
        </>
    );
}