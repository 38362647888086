import { useEffect, useState } from 'react';
import Header from '../components/Header';
import { displayTopTracks } from '../App';

export default function Tracks() {
    const [tracks, setTracks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTopTracks = async (timeRange) => {
            setIsLoading(true);
            const fetchedTracks = await displayTopTracks(timeRange);
            setTracks(fetchedTracks);
            setIsLoading(false);
        };

        fetchTopTracks('short_term');
    }, []);

    const handleTimeRangeChange = async (timeRange) => {
        setIsLoading(true);
        const fetchedTracks = await displayTopTracks(timeRange);
        setTracks(fetchedTracks);
        setIsLoading(false);
    };

    return (
        <>
            <Header />
            <h1 id='titleHeader'>Top Tracks</h1>
            <div id="tracksContainer">
                <div id="tabs">
                    <button id="shortTermBtn" className="tab-button" onClick={() => handleTimeRangeChange('short_term')}>Last 4 weeks</button>
                    <div className="divider"></div>
                    <button id="mediumTermBtn" className="tab-button" onClick={() => handleTimeRangeChange('medium_term')}>Last 6 months</button>
                    <div className="divider"></div>
                    <button id="longTermBtn" className="tab-button" onClick={() => handleTimeRangeChange('long_term')}>The past year</button>
                </div>
                {!isLoading && tracks.length > 0 ? (
                    <ul id="topTracksList" className="top-tracks-list">
                        {tracks.map((track, index) => (
                            <li key={index}>
                                <a href={track.external_urls.spotify} target="_blank" rel="noopener noreferrer" className="track-link">
                                    <div className="track">
                                        <div className="track-number">
                                            <span>{index + 1}</span>
                                        </div>
                                        <img src={track.album.images[0].url} alt="Album Artwork" className="album-image" />
                                        <div className="track-details">
                                            <div className="track-name">{track.name}</div>
                                            <div className="track-artists">{track.artists.map(artist => artist.name).join(', ')}</div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </>
    );
}