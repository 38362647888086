import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { displayTopArtists, getSavedTracks, getRecommendations, createPlaylist } from '../App'; // Import createPlaylist

export default function Playlist() {
    const [topArtists, setTopArtists] = useState([]);
    const [selectedArtists, setSelectedArtists] = useState([]);
    const [showArtists, setShowArtists] = useState(false);
    const [savedTracksList, setSavedTracksList] = useState([]);
    const [playlistUrl, setPlaylistUrl] = useState(''); // State to hold playlist URL
    const [playlistId, setPlaylistId] = useState('1tc6BdJbu9JCenvzz5xPom'); // State to hold playlistId


    useEffect(() => {
        async function fetchData() {
            const artists = await displayTopArtists('long_term');
            setTopArtists(artists);
        }

        if (showArtists) {
            fetchData();
        }
    }, [showArtists]);

    const handleGetStarted = () => {
        setShowArtists(true);
    };

    const toggleSelectArtist = (artist) => {
        setSelectedArtists((prevSelectedArtists) => {
            const artistName = artist.name;
            const updatedArtists = prevSelectedArtists.includes(artistName)
                ? prevSelectedArtists.filter((selectedArtist) => selectedArtist !== artistName)
                : [...prevSelectedArtists, artistName];

            console.log('Selected Artists:', updatedArtists);

            return updatedArtists;
        });
    };

    // Helper function to get random tracks from a list
    const getRandomTracks = (tracks, count) => {
        if (!tracks || tracks.length === 0) {
            return []; // Return an empty array if tracks is undefined or an empty array
        }

        const shuffledTracks = [...tracks].sort(() => 0.5 - Math.random());
        return shuffledTracks.slice(0, count);
    };

    const createPlaylistButton = async () => {
        try {
            var loading = document.getElementById('loading');
            var progress = document.getElementById('progress');

            loading.style.display = 'block';
            progress.style.display = 'block';

            let offset = 0;
            let allSavedTracks = [];
            setShowArtists(false);

            while (true) {
                const savedTracks = await getSavedTracks(offset);
                console.log('Saved Tracks:', savedTracks);

                if (savedTracks.length === 0) {
                    break; // Exit the loop if there are no more tracks
                }

                allSavedTracks = [...allSavedTracks, ...savedTracks];
                offset += 50; // Increment the offset by 50 since the API fetches 50 tracks per request
            }

            console.log('All Saved Tracks:', allSavedTracks);

            // Filter tracks by selected artists
            const selectedTracks = allSavedTracks.filter((track) => {
                if (!track || !track.track || !track.track.artists || track.track.artists.length === 0) {
                    console.log('No valid artist information found in this track object');
                    return false;
                }

                const trackArtistsNames = track.track.artists.map((artist) => artist.name);
                return selectedArtists.some((artist) => trackArtistsNames.includes(artist));
            });

            // Get three random tracks for each selected artist
            const randomTracks = [];
            selectedArtists.forEach((artist) => {
                const artistTracks = selectedTracks.filter((track) => {
                    const trackArtistsNames = track.track.artists.map((artist) => artist.name);
                    return trackArtistsNames.includes(artist);
                });

                if (artistTracks.length > 0) {
                    const randomArtistTracks = getRandomTracks(artistTracks, 3);
                    randomTracks.push(...randomArtistTracks);
                }
            });

            console.log('Random Tracks:', randomTracks);

            // Get recommendations for each random track
            const combinedTracks = [];
            for (let i = 0; i < randomTracks.length; i++) {
                const track = randomTracks[i];
                const recommendation = await getRecommendations(track.track.id);
                console.log(`${track.track.name} - ${track.track.artists[0].name}`);
                console.log(`Recommendation: ${recommendation[0].name} - ${recommendation[0].artists[0].name}`);

                // Save only the track URI to the savedTracksList
                combinedTracks.push(track.track.uri);

                // Add recommendations' track URIs to the savedTracksList
                recommendation.forEach((rec) => {
                    combinedTracks.push(rec.uri);
                });
            }

            console.log('Combined Tracks:', combinedTracks);
            // Save combinedTracks (track URIs) to the savedTracksList
            setSavedTracksList(combinedTracks);

            // Create playlist with the list of track URIs
            const playlist = await createPlaylist(combinedTracks);
            console.log('Created Playlist:', playlist);

            // Set the playlist URL state
            setPlaylistUrl(playlist.external_urls.spotify);
            setPlaylistId(playlist.id);

            // Hide artists after playlist is created
            setShowArtists(false);

            loading.style.display = 'none';
            progress.style.display = 'none';

        } catch (error) {
            console.error('Error fetching saved tracks:', error);
        }
    };

    return (
        <>
            <Header />
            <h1 id='titleHeader'>Playlist Generator</h1>

            <div id="start">
                <p>Create a personalized playlist with recommendations featuring your favorite artists and songs in your library.</p>
            </div>

            <button id="getStartedButton" onClick={handleGetStarted}>
                Get Started
            </button>

            {showArtists && (
                <>
                    <div id="showArtistsContainer" className="artists-container">
                        {topArtists.slice(0, 20).map((artist, index) => (
                            <div
                                className={`artistCard ${selectedArtists.includes(artist.name) ? 'selected' : ''}`}
                                key={index}
                                onClick={() => toggleSelectArtist(artist)}
                            >
                                <img
                                    src={artist.images.length > 0 ? artist.images[0].url : ''}
                                    alt={artist.name}
                                    className="profile-image"
                                />
                                <p className="artist-namePlaylist">{artist.name}</p>
                            </div>
                        ))}
                    </div>

                    <button
                        id="createPlaylistButton"
                        style={{ display: selectedArtists.length > 0 ? 'block' : 'none' }}
                        onClick={createPlaylistButton}
                    >
                        Create Playlist
                    </button>
                </>
            )}

            <div className="scene" id="loading" style={{ display: 'none' }}>
                <div className="objects">
                    <div className="square"></div>
                    <div className="circle"></div>
                    <div className="triangle"></div>
                </div>
                <div className="wizard">
                    <div className="body"></div>
                    <div className="right-arm">
                        <div className="right-hand"></div>
                    </div>
                    <div className="left-arm">
                        <div className="left-hand"></div>
                    </div>
                    <div className="head">
                        <div className="beard"></div>
                        <div className="face">
                            <div className="adds"></div>
                        </div>
                        <div className="hat">
                            <div className="hat-of-the-hat"></div>
                            <div className="four-point-star --first"></div>
                            <div className="four-point-star --second"></div>
                            <div className="four-point-star --third"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="progress" id="progress" style={{ display: 'none' }}></div>

            {/* Display the created playlist */}
            {playlistUrl && (
                <div id="playlistContainer">
                    <iframe
                        title="Spotify Embed: Recommendation Playlist"
                        src={`https://open.spotify.com/embed/playlist/${playlistId}?utm_source=generator&theme=0`}
                        width="100%"
                        height="100%"
                        style={{ minHeight: '360px' }}
                        frameBorder="0"
                        allowtransparency="true"
                        allow="encrypted-media"
                    ></iframe>
                </div>
            )}
        </>
    );
}