import { useEffect, useState } from 'react';
import Header from '../components/Header';
import { displayTopArtists } from '../App';

export default function Recent() {
    const [artists, setArtists] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTopArtists = async () => {
            setIsLoading(true);
            const fetchedArtists = await displayTopArtists('short_term');
            setArtists(fetchedArtists);
            setIsLoading(false);
        };

        fetchTopArtists();
    }, []);

    const handleTimeRangeChange = async (timeRange) => {
        setIsLoading(true);
        const fetchedArtists = await displayTopArtists(timeRange);
        setArtists(fetchedArtists);
        setIsLoading(false);
    };

    return (
        <>
            <Header />
            <h1 id='titleHeader'>Top Artists</h1>
            <div id="artistsContainer">
                <div id="tabs">
                    <button id="shortTermBtn" className="tab-button" onClick={() => handleTimeRangeChange('short_term')}>Last 4 weeks</button>
                    <div className="divider"></div>
                    <button id="mediumTermBtn" className="tab-button" onClick={() => handleTimeRangeChange('medium_term')}>Last 6 months</button>
                    <div className="divider"></div>
                    <button id="longTermBtn" className="tab-button" onClick={() => handleTimeRangeChange('long_term')}>The past year</button>
                </div>
                {!isLoading && artists.length > 0 ? (
                    <div className="artists-list">
                        {artists.map((artist, index) => (
                            <div key={index} className="artist">
                                {artist.images && artist.images.length > 0 && artist.images[0].url && artist.name ? (
                                    <a href={artist.external_urls.spotify} target="_blank" rel="noopener noreferrer" className="artist-link">
                                        <div className="artist-content">
                                            <img src={artist.images[0].url} alt="{artist.name}" className="artist-image" />
                                            <div className="artist-nameArtists">{index + 1}. {artist.name}</div> {/* Modified line */}
                                        </div>
                                    </a>
                                ) : (
                                    <div className="artist-content">
                                        <div className="artist-image-placeholder">No Image</div>
                                        <div className="artist-nameArtists">{index + 1} Unknown Artist</div> {/* Modified line */}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </>
    );
}